import React from "react"
import { graphql } from "gatsby"
import SEO from "components/seo"
import Hero from "components/Hero"

const IndexPage = ({data}) => {
    return (
    <div>
      <SEO title="Home" />
      <main>
        <Hero background={data.background.childImageSharp.fluid} skull={data.skull.childImageSharp.fluid}/>
      </main>
    </div>
  )
}
export default IndexPage

export const indexQuery = graphql`
query backgroundImageQuery {
  background: file(relativePath: { eq: "background.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    skull: file(relativePath: { eq: "skull.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
}
`

