import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import BGImage from "components/BGImage"
import { useButtonSupportQuery } from "hooks/useButtonSupportQuery"
import { useButtonContentQuery } from "hooks/useButtonContentQuery"

const Hero = ({ background, skull, className, children }) => {
  const supportBtn = useButtonSupportQuery()
  const contentBtn = useButtonContentQuery()
  return (
    <BGImage image={background}>
      <div class="d-flex flex-column" style={{position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"}}>
        <div>
          <Img fluid={skull} />
        </div>
        <div class="p-2">
          <Link to="/support">
            <Img fixed={supportBtn} />
          </Link>{" "}
          <Link to="/content">
            <Img fixed={contentBtn} />
          </Link>
        </div>
      </div>
    </BGImage>
  )
}

export default Hero

// <BGImage image={image}>
// <ButtonWrapper>
//     <InnerButtonWrapper>
//     <Link to='/support'>
//         <Img fixed={supportBtn}/>
//     </Link>
//     </InnerButtonWrapper>
//     <InnerButtonWrapper>
//     <Link to='/content'>
//         <Img fixed={contentBtn}/>
//     </Link>
//     </InnerButtonWrapper>
// </ButtonWrapper>
// </BGImage>
