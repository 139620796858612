import { useStaticQuery, graphql } from "gatsby"

export const useButtonSupportQuery = () => {
    const data = useStaticQuery(graphql`
    query ButtonSupportQuery {
        file(relativePath: { eq: "support.jpg" }) {
        childImageSharp {
          fixed (height: 50){
             ...GatsbyImageSharpFixed
        }
      }
    }
    }
    `)
    return data.file.childImageSharp.fixed;
}
