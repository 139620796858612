import { useStaticQuery, graphql } from "gatsby"

export const useButtonContentQuery = () => {
    const data = useStaticQuery(graphql`
    query ButtonContentQuery {
        file(relativePath: { eq: "content.png" }) {
        childImageSharp {
          fixed (height: 50){
             ...GatsbyImageSharpFixed
        }
      }
    }
    }
    `)
    return data.file.childImageSharp.fixed;
}
